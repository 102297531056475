var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrap"},[_c('div',{staticClass:"bannerImg"},[(_vm.$route.path == '/home')?_c('img',{attrs:{"src":require("@/assets/banner/home1.jpg")}}):_vm._e(),(_vm.$route.path == '/about')?_c('img',{attrs:{"src":require("@/assets/banner/about.jpg")}}):_vm._e(),(_vm.$route.path.includes('/product'))?_c('img',{attrs:{"src":require("@/assets/banner/product.jpg")}}):_vm._e(),(_vm.$route.path == '/service')?_c('img',{attrs:{"src":require("@/assets/banner/service.jpg")}}):_vm._e(),(_vm.$route.path.includes('/customers'))?_c('img',{attrs:{"src":require("@/assets/banner/customer1.jpg")}}):_vm._e(),(_vm.$route.path == '/contact')?_c('img',{attrs:{"src":require("@/assets/banner/contact.jpg")}}):_vm._e()]),_c('div',{staticClass:"header"},[_vm._m(0),_c('ul',{staticClass:"nav"},[_c('li',{class:_vm.$route.path == '/home' ? 'active' : '',on:{"click":function($event){return _vm.$router.push('/home')}}},[_vm._m(1)]),_c('li',{class:_vm.$route.path == '/product/crgoPage' ? 'active' : '',on:{"click":function($event){return _vm.$router.push('/product/crgoPage')}}},[_vm._m(2)]),_c('li',{class:_vm.$route.path == '/service' ? 'active' : '',on:{"click":function($event){return _vm.$router.push('/service')}}},[_vm._m(3)]),_c('li',{class:_vm.$route.path.includes('/customers') ? 'active' : '',on:{"click":function($event){return _vm.$router.push('/customers')}}},[_vm._m(4)]),_c('li',{class:_vm.$route.path == '/about' ? 'active' : '',on:{"click":function($event){return _vm.$router.push('/about')}}},[_vm._m(5)]),_c('li',{class:_vm.$route.path == '/contact' ? 'active' : '',on:{"click":function($event){return _vm.$router.push('/contact')}}},[_vm._m(6)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-logo"},[_c('img',{staticClass:"logo-img",attrs:{"src":require("@/assets/logo.png")}}),_c('h3',[_vm._v("SHANGHAI XINCAN")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"b"},[_c('span',[_vm._v("HOME")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"b"},[_c('span',[_vm._v("PRODUCT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"b"},[_c('span',[_vm._v("SERVICE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"b"},[_c('span',[_vm._v("CUSTOMERS")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"b"},[_c('span',[_vm._v("ABOUT")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"b"},[_c('span',[_vm._v("CONTACT")])])
}]

export { render, staticRenderFns }