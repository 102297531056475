import Vue from "vue";
import VueRouter from "vue-router";
const routerPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/HomePage"),
  },
  {
    path: "/about",
    name: "ABOUT",
    component: () => import("@/views/AboutPage"),
  },
  {
    path: "/product",
    name: "PRODUCT",
    component: () => import("@/views/ProductPage"),
    children: [
      {
        path: "",
        redirect: "crgoPage", // 默认重定向到路由
      },
      {
        path: "crgoPage",
        name: "CRGO/Si-Steel",
        component: () => import("@/views/productView/CrgoLogoPage"),
      },
      {
        path: "slittingCoils",
        name: "Slitting Coils",
        component: () => import("@/views/productView/SlittingCoilsPage"),
      },
      {
        path: "laminationSheets",
        name: "Lamination Sheets",
        component: () => import("@/views/productView/LaminationSheetsPage"),
      },
      {
        path: "laminationCore",
        name: "Lamination Core",
        component: () => import("@/views/productView/LaminationCorePage"),
      },
    ],
  },
  {
    path: "/service",
    name: "SERVICE",
    component: () => import("@/views/ServicePage"),
  },
  {
    path: "/customers",
    name: "CUSTOMERS",
    component: () => import("@/views/CustomersPage"),
    redirect: "/customers/domestic",
    children: [
      {
        path: "domestic",
        name: "DOMESTIC",
        component: () => import("@/views/DomesticPage"),
      },
      {
        path: "export",
        name: "EXPORT",
        component: () => import("@/views/ExportPage"),
      },
    ],
  },
  {
    path: "/contact",
    name: "CONTACT",
    component: () => import("@/views/ContactPage"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
