<template>
   <div id="app">
      <header-nav></header-nav>
      <router-view/>
      <footer-nav></footer-nav>
  </div>
</template>
<script>
import headerNav from "@/components/HeaderNav.vue";
import FooterNav from '@/components/FooterNav.vue'
export default {
    name: "",
    components: { headerNav,FooterNav },
    // 定义属性
    data() {
      return {};
    },
    computed: {},
    methods: {},
    created() {},
    mounted() {},
  };
</script>
<style>

</style>
