<template>
  <div class="wrap">
    <div class="bannerImg">
      <!-- <div v-if="$route.path == '/home'">
        <el-carousel height="320px">
          <el-carousel-item v-for="item in homeSwiper" :key="item.id">
            <img :src="item.url" />
          </el-carousel-item>
        </el-carousel>
      </div> -->
      <img src="@/assets/banner/home1.jpg" v-if="$route.path == '/home'" />
      <!-- <img src="@/assets/banner/home1.png"  /> -->
      <img src="@/assets/banner/about.jpg" v-if="$route.path == '/about'" />
      <img
        src="@/assets/banner/product.jpg"
        v-if="$route.path.includes('/product')"
      />
      <img src="@/assets/banner/service.jpg" v-if="$route.path == '/service'" />
      <img
        src="@/assets/banner/customer1.jpg"
        v-if="$route.path.includes('/customers')"
      />
      <img src="@/assets/banner/contact.jpg" v-if="$route.path == '/contact'" />
    </div>
    <div class="header">
      <div class="h-logo">
        <img src="@/assets/logo.png" class="logo-img" />
        <h3>SHANGHAI XINCAN</h3>
      </div>
      <ul class="nav">
        <li
          :class="$route.path == '/home' ? 'active' : ''"
          @click="$router.push('/home')"
        >
          <div class="b"><span>HOME</span></div>
        </li>
        <li
          :class="$route.path == '/product/crgoPage' ? 'active' : ''"
          @click="$router.push('/product/crgoPage')"
        >
          <div class="b"><span>PRODUCT</span></div>
        </li>
        <li
          :class="$route.path == '/service' ? 'active' : ''"
          @click="$router.push('/service')"
        >
          <div class="b"><span>SERVICE</span></div>
        </li>
        <li
          :class="$route.path.includes('/customers') ? 'active' : ''"
          @click="$router.push('/customers')"
        >
          <div class="b"><span>CUSTOMERS</span></div>
        </li>
        <li
          :class="$route.path == '/about' ? 'active' : ''"
          @click="$router.push('/about')"
        >
          <div class="b"><span>ABOUT</span></div>
        </li>
        <li
          :class="$route.path == '/contact' ? 'active' : ''"
          @click="$router.push('/contact')"
        >
          <div class="b"><span>CONTACT</span></div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header-Nav",
  components: {},
  // 定义属性
  data() {
    return {
      homeSwiper: [
        { id: 1, url: require("@/assets/banner/home1.png") },
        { id: 2, url: require("@/assets/banner/home2.jpg") },
      ],
    };
  },
  computed: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>

<style lang="less" scoped>
.wrap {
  .bannerImg {
    width: 100%;
    height: 320px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.header {
  width: 100%;
  margin: 0 auto;
  height: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .h-logo {
    display: flex;
    align-items: center;
    margin-right: 5%;
    h3 {
      font-size: 30px;
      color: #064ea0;
      font-family: serif;
      font-style: italic;
    }
  }
  .logo-img {
    height: 100px;
    width: 120px;
  }
  .nav {
    display: flex;
    flex: 1;
    justify-content: space-between;
    border-top: 4px double rgba(3, 78, 160);
    border-bottom: 4px double rgba(3, 78, 160);
    li {
      cursor: pointer;
      font-size: 18px;
      padding: 0 1.5vw;
      line-height: 90px;
      display: block;
      font-weight: normal;
      text-align: center;
      position: relative;
      color: #e50014;
      &.active {
        color: rgba(3, 78, 160);
      }
      .b::after {
        content: "";
        position: absolute;
        left: 0%;
        bottom: 0px;
        width: 100%;
        height: 0%;
        background: rgba(3, 78, 160, 0.8);
        opacity: 1;
        z-index: 1;
        transition: all 0.3s ease;
      }
      .b:hover::after {
        height: 100%;
      }
      .b:hover {
        color: #fff;
      }
      .b span {
        position: relative;
        z-index: 22;
      }
    }
  }
}
</style>
